import html2canvas from "html2canvas";
import jsPDF from 'jspdf';


const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
};


const exportAsImageDev = async (el) => {
    const canvas = await html2canvas(el);
    // const image = canvas.toDataURL("image/png", 1.0);
    return canvas.toDataURL("image/png", 1.0);
}


const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};

export const downloadPDF = async (elements, setProgress) => {
    const pdf = new jsPDF('landscape');
    elements = elements.filter(el => el !== null);

    if (elements.length === 0) {
        console.warn("No elements to export.");
        return;
    }

    const pdfWidth = pdf.internal.pageSize.getWidth();
    let yOffset = 0;
    const maxImagesPerPage = 2;
    const totalElements = elements.length;

    for (const [index, el] of elements.entries()) {
        if (el && el.getElement() && document.body.contains(el.getElement())) {
            const image = await exportAsImageDev(el.getElement());
            const imgProps = pdf.getImageProperties(image);
            const ratio = pdfWidth / imgProps.width;
            const imgHeight = imgProps.height * ratio;

            if (index > 0 && index % maxImagesPerPage === 0) {
                pdf.addPage();
                yOffset = 0; // Reset yOffset for new page
            }

            pdf.addImage(image, 'PNG', 0, yOffset, pdfWidth, imgHeight);
            yOffset += imgHeight; // Update yOffset for next image
        } else {
            console.warn(`Element at index ${index} is not attached to the document.`);
        }
        setProgress(Math.round(((index + 1) / totalElements) * 100));
    }

    pdf.save("document.pdf");
    setProgress(0); // Reset progress after completion

};

export default exportAsImage;