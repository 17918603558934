import React from "react";
import styled from "styled-components";
import { LinkedinIcon } from "../../assets/icons";

const Container = styled.footer`
  background-color: var(--secondary);
  font-size: 0.6rem;
  border-radius: 30px;
  margin: 1rem auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 61.313em) {
    text-align: center;
    font-size: 0.8rem;
    flex-direction: row;
    padding: 0.5rem 2rem;
    & > div:nth-of-type(1) {
      justify-content: left;
    }
    & > div:nth-of-type(3) {
      justify-content: flex-end;
    }
  }
`;
const IconWrapper = styled.div`
  width: 25px;
  height: auto;
`;
const FooterLink = styled.a`
  margin: 0.5rem auto;
  @media (min-width: 61.313em) {
    margin: 0;
  }
  &:hover {
    svg {
      fill: var(--primary);
    }
  }
`;
export default function Footer() {
  return (
    <Container>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div>© {new Date().getFullYear()} Federate Consulting. </div>
        <div>| All Rights Reserved</div>
      </div>
      <div>
        <FooterLink
          href="https://federateconsulting.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </FooterLink>
      </div>
      <div>
        <FooterLink
          href={"https://www.linkedin.com/company/federate-one/"}
          target="_blank"
          rel="noreferrer"
        >
          <IconWrapper>
            <LinkedinIcon />
          </IconWrapper>
        </FooterLink>
      </div>
    </Container>
  );
}
