import axios from "axios";
import React, { useContext, useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState";
import Loading from "../utils/loading";
import WeeklyBarChart from "./WeeklyBarChart";
import WeeklyGraph from "./WeeklyGraph";
import moment from "moment";
import exportAsImage from "../utils/exportImage";
import { literToKiloliter, sumList } from "../utils/utils";


const GraphWrapper = styled.div`
  margin: 2rem auto;
  background-color: #fff;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
`;

const DownloadBtn = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  margin: 0 1rem;
  color: grey;

  &:hover {
    color: green;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  /* @media (min-width: 61.313em) {
    align-items: center;
  justify-content: space-between;

 } */
`;
const HighLight = styled.h3`
color:var(--primary);`


const HighLight1 = styled.span`
color:var(--primary);
font-weight: bold;
`

// function capitalize(word) {
//   return word[0].toUpperCase() + word.slice(1).toLowerCase();
// }

const WeeklyReportV2 = forwardRef(({ sensor_type, sensor_id, location, startDate, endDate, reportType, timePeriod, motor_id }, ref) => {
  const exportRef = useRef();
  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [totalLitre, setTotalLitre] = useState(0);
  const dateFormat = "DD MMM YYYY";
  useImperativeHandle(ref, () => ({
    getElement: () => exportRef.current,
  }));
  // const saveCanvas1 = () => {
  //   //save to png
  //   // const canvasSave = document.getElementById("canvas1");
  //   // canvasSave.toBlob(function (blob) {
  //   //   saveAs(blob, `last week___${sensor_type}.jpg`);
  //   // });

  //   exportAsImage(exportRef, `${sensor_type}`);
  // };
  async function fetchList(token) {
    try {
      if (startDate.split(" ").length !== 2) {
        startDate = startDate + " 00:00:00";
      }
      if (endDate.split(" ").length !== 2) {
        endDate = endDate + " 23:59:59";
      }

      const consum_fill_url = `/sensor/date-wise-water-opeartion?sensor_id=${sensor_id}&sensor_type=${sensor_type}&start_date=${startDate}&end_date=${endDate}&type=${reportType}`;
      const trend_url = `/sensor/date-wise-report?sensor_id=${sensor_id}&sensor_type=${sensor_type}&start_date=${startDate}&end_date=${endDate}&motor_id=${motor_id}`
      const url = reportType === 'Consumption' || reportType === 'Filled' ? consum_fill_url : trend_url

      const res = await axios.get(`${process.env.REACT_APP_API}${url}`, {
        headers: { Authorization: token[0] },
      }
      );


      const today = moment().format('YYYY-MM-DD');
      const end_date = moment(endDate).format('YYYY-MM-DD');

      if (consum_fill_url === url) {
        const total = res.data.data.reduce((acc, curr) => {
          return Number(acc) + Number(curr);
        }, 0);

        setTotalLitre(total);
      }

      if ((today === end_date || timePeriod === 'custom-time') && (reportType === 'Consumption' || reportType === 'Filled')) {

        const waterOperation = await axios.get(`${process.env.REACT_APP_API}/sensor/water_operation?sensor_id=${sensor_id}&type=${"daily"}&start_date=${startDate}&end_date=${endDate}`,
          {
            headers: { Authorization: token[0] },
          }
        );

        const newData = res.data;
        const today_data = waterOperation.data[reportType.toLowerCase()];

        newData.data.push(today_data);
        newData.dates.push(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        newData.totalLitre = sumList(newData.data);
        setData(newData);
      }


      if (res) {
        setData(res.data);
        setLoading(false);

      }



    } catch (err) {
      console.log(err.message);
      setData([]);
      setLoading(false);

    }

  }
  useEffect(() => {



    fetchList(state.token);
  }, []);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <>
      <GraphWrapper ref={exportRef}>
        <Header>
          <div>

            <HighLight>
              {location}
            </HighLight>
            <h5>
              {
                reportType === 'Consumption' || reportType === 'Filled' ? "Approximate" : ""
              } {sensor_type}
              {
                endDate ? ` from ${moment(startDate).format(dateFormat)} to ${moment(endDate).format(dateFormat)}` : ` for ${moment(startDate).format(dateFormat)}`
              }
              <HighLight1>
                {
                  Data?.totalLitre ? ` - Total ${literToKiloliter(Data.totalLitre)}` : ""
                }
              </HighLight1>
            </h5>


          </div>

          <DownloadBtn title={"Download graph"}>
            <FaCloudDownloadAlt onClick={() => exportAsImage(exportRef.current, `${sensor_type}_${startDate}-${endDate}`)} size={25} />
          </DownloadBtn>
        </Header>

        {
          reportType === 'Consumption' || reportType === 'Filled' ?
            <WeeklyBarChart
              dates={Data.dates}
              water_data={Data.data}
            /> : <WeeklyGraph
              timestamps={Data.timestamps}
              data={Data.datas}
              unit={Data.unit}
              type={sensor_type}
              startDate={startDate}
              endDate={endDate}
            />
        }



      </GraphWrapper>

    </>
  );
});

export default WeeklyReportV2;
