import axios from "axios";
import moment from "moment";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState";
import exportAsImage from "../utils/exportImage";
import Loading from "../utils/loading";
import WeeklyBarChart from "./WeeklyBarChart";
import { literToKiloliter, sumList } from "../utils/utils";
import { GrAnalytics } from "react-icons/gr";


const GraphWrapper = styled.div`
  margin: 2rem auto;
  background-color: #fff;
  padding: 1.3rem;
  width: 100%;
  min-height: 365px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
`;

const DownloadBtn = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  margin: 0 1rem;
  color: grey;

  &:hover {
    color: green;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  /* @media (min-width: 61.313em) {
    align-items: center;
  justify-content: space-between;

 } */
`;
const HighLight = styled.h3`
color:var(--primary);`

const HighLight1 = styled.span`
color:var(--primary);
font-weight: bold;
`

// function capitalize(word) {
//   return word[0].toUpperCase() + word.slice(1).toLowerCase();
// }

const HourlyReport = forwardRef(({ sensor_type, sensor_id, location, startDate, endDate, reportType, timePeriod, motor_id }, ref) => {
  const exportRef = useRef();
  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [totalLitre, setTotalLitre] = useState(0);
  const dateFormat = "DD MMM YYYY";


  useImperativeHandle(ref, () => ({
    getElement: () => exportRef.current,
  }));
  // const saveCanvas1 = () => {
  //   //save to png
  //   // const canvasSave = document.getElementById("canvas1");
  //   // canvasSave.toBlob(function (blob) {
  //   //   saveAs(blob, `last week___${sensor_type}.jpg`);
  //   // });

  //   exportAsImage(exportRef, `${sensor_type}`);
  // };
  async function fetchList(token) {
    try {
      if (startDate.split(" ").length !== 2) {
        startDate = startDate + " 00:00:00";
      }
      if (endDate.split(" ").length !== 2) {
        endDate = endDate + " 23:59:59";
      }

      const start_date = moment(startDate).format('YYYY-MM-DD');
      const end_date = moment(endDate).format('YYYY-MM-DD');

      if ((reportType === 'Consumption' || reportType === 'Filled') && start_date === end_date) {

        const res = await axios.get(`${process.env.REACT_APP_API}/sensor/hourly_water_report?sensor_id=${sensor_id}&start_date=${startDate}&end_date=${endDate}`,
          {
            headers: { Authorization: token[0] },
          }
        );

        if (reportType === 'Consumption') {
          setData({
            dates: res.data?.consumtion?.dates,
            data: res.data?.consumtion?.datas,
            totalLitre: sumList(res.data?.consumtion?.datas)
          });
        }

        if (reportType === 'Filled') {

          setData({
            dates: res.data?.filled?.dates,
            data: res.data?.filled?.datas,
            totalLitre: sumList(res.data?.filled?.datas)
          });

        }

        setLoading(false);


      }





    } catch (err) {
      console.log(err.message);
      setData([]);
      setLoading(false);

    }

  }
  useEffect(() => {



    fetchList(state.token);
  }, []);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  // console.log({ Data, reportType });
  return (
    <>
      <GraphWrapper ref={exportRef}>
        <Header>
          <div>

            <HighLight>
              {location}
            </HighLight>
            <h5>
              {
                reportType === 'Consumption' || reportType === 'Filled' ? "Approximate" : ""
              } {sensor_type}
              {
                endDate ? ` from ${moment(startDate).format(dateFormat)} to ${moment(endDate).format(dateFormat)}` : ` for ${moment(startDate).format(dateFormat)}`
              }

              <HighLight1>
                {
                  Data?.totalLitre ? ` - Total ${literToKiloliter(Data.totalLitre)}` : ""
                }
              </HighLight1>
            </h5>
          </div>

          <DownloadBtn title={"Download graph"}>
            <FaCloudDownloadAlt onClick={() => exportAsImage(exportRef.current, `${sensor_type}_${startDate}-${endDate}`)} size={25} />
          </DownloadBtn>
        </Header>

        {
          (reportType === 'Consumption' || reportType === 'Filled') &&
          <WeeklyBarChart
            dates={Data.dates}
            water_data={Data.data}
            hourly={true}
          />
        }



      </GraphWrapper>

    </>
  );
});

export default HourlyReport;
