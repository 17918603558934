import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import styled from "styled-components";
import { ReportSvg } from "../../assets/svg";
import { GlobalState } from "../../GlobalState";
import Banner from "../utils/banner";
import Loading from "../utils/loading";
import Tooltip from "../utils/Tooltip";
import WeeklyReport from "./WeeklyReport";
import moment from "moment";
import { reportTimePeriod } from "../utils/utils";
import HourlyReport from "./HourlyReport";
import WeeklyReportV2 from "./WeeklyReportV2";
import { downloadPDF } from "../utils/exportImage";
import { FaDownload } from "react-icons/fa";
import ProgressBar from "../utils/ProgressBar";

const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  @media (min-width: 61.313em) {
    width: 80%;
  }
`;
const StyledTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledBtn = styled.button`
  display: block;
  cursor: pointer;
  margin: 0.8rem auto;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  // width:80%;

  border-radius: 30px;
  &:disabled{
    background-color: grey;
  }
  @media (min-width: 61.313em) {
    
    // width:20%;
  
  }

`;

const IconBtn = styled.button`
  cursor: pointer;
  color: var(--background);
  background-color: var(--primary);
  border: none;
  outline: none;
  padding: 0.5rem;
  display: block;

  border-radius: 30px;
  &:disabled{
    background-color: grey;
  }
`;
const StyledSelect = styled.select`
  // cross browser styles
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50% #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  min-width: 250px;

  outline: none;
  font-weight: bolder;
  padding: 0.8rem;
  border: 1px solid var(--text);
  margin: 0.5rem;
  border-radius: 30px;
  
  &:focus {
    border: 2px solid var(--primary);
  }
`;
const StyledInput = styled.input`

  background-color: #fff;
  min-width:250px;
  outline: none;
  font-weight: bolder;
  padding: 0.8rem;
  border: 1px solid var(--text);
  margin: 0.5rem;
  border-radius: 30px;
  
  &:focus {
    border: 2px solid var(--primary);
  }
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 61.313em) {
  flex-direction:row;

  }
`;

const StyledForm = styled.form`

display: flex;

  align-items: center;
  flex-direction:column;

 @media (min-width: 61.313em) {
  flex-direction:column;

  }

`


export default function Report() {
  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  // const elementsRef = useRef([]);
  const componentRefs = useRef({});
  const [progress, setProgress] = useState(0);



  const [reportType, setReportType] = useState('DEFAULT');
  const [list, setList] = useState([]);
  const [showGraph, setshowGraph] = useState(false);
  const [typeValue, settypeValue] = useState('DEFAULT');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [maxEndDate, setMaxEndDate] = useState(null)
  const [reportDate, setReportDate] = useState(null)
  const [timePeriod, setTimePeriod] = useState(null);

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showWeekPicker, setShowWeekPicker] = useState(false)
  const [showMonthPicker, setShowMonthPicker] = useState(false)


  const [showDateTimePicker, setShowDateTimePicker] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault();
    componentRefs.current = {};
    if (!typeValue || !reportType)
      return
    setshowGraph(true)
  };

  useEffect(() => {
    async function fetchData(token) {
      try {
        const sensor_type = await axios.get(`${process.env.REACT_APP_API}/user/get_report_list`, {
          headers: { Authorization: token[0] },
        });

        if (sensor_type) {
          console.log(sensor_type.data);
          setList(sensor_type.data);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    var d = new Date();
    d.setDate(d.getDate() - 180);
    setMinDate(moment(d).format('YYYY-MM-DD'));

    setMaxDate(moment(new Date()).format('YYYY-MM-DD'));
    fetchData(state.token);
  }, [state.token]);


  const addRef = (id, ref) => {
    if (ref && !componentRefs.current[id]) {
      componentRefs.current[id] = ref;
    }
  };

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <Container>
      <Banner title={`View Reports`} subtitle="">
        <ReportSvg />
      </Banner>

      <StyledTooltip>
        <Tooltip
          content={
            <>
              Hang tight! Sometimes it may take <br /> upto 5 sec to generate
              report.
            </>
          }
          direction="left"
          delay="0"
        >
          <IoInformationCircle size={25} style={{ color: "grey" }} />
        </Tooltip>
      </StyledTooltip>


      {/* <FormWrapper> */}
      <StyledForm onSubmit={handleSubmit}>
        <FormWrapper
        // style={
        //   {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     flexWrap: 'wrap',
        //     justifyContent: 'center',
        //     alignItems: 'center'
        //   }
        // }

        >


          <StyledSelect
            onChange={(e) => {
              setshowGraph(false);
              setShowDatePicker(false);
              setShowWeekPicker(false)
              setShowMonthPicker(false)
              setShowDateTimePicker(false)
              setReportType(e.target.value);
              if (Object.keys(list[e.target.value]).length === 1) {
                settypeValue(Object.keys(list[e.target.value])[0])

              } else {
                settypeValue('DEFAULT');
              }
            }}
            value={reportType}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select Report
            </option>

            {Object.keys(list).map(function (sensor, index) {
              return (
                <option key={index} value={sensor}>
                  {sensor}
                </option>
              );
            })}
          </StyledSelect>

          <StyledSelect
            onChange={(e) => {
              setshowGraph(false);
              settypeValue(e.target.value);
            }}
            value={typeValue}
            defaultValue={"DEFAULT"}
          >
            <option value='DEFAULT' disabled>
              Select Sensor Type
            </option>

            {Object.keys(list[reportType] || []).map(function (sensor, index) {
              return (
                <option key={index} value={sensor}>
                  {sensor}
                </option>
              );
            })}
          </StyledSelect>

          <StyledSelect
            onChange={(e) => {
              setshowGraph(false);
              setShowDatePicker(false);
              setShowWeekPicker(false)
              setShowMonthPicker(false)
              setShowDateTimePicker(false)


              setTimePeriod(e.target.value);


              const value = e.target.value;

              if (value === 'today') {
                setStartDate(moment(new Date()).format('YYYY-MM-DD'));
                setEndDate(moment(new Date()).format('YYYY-MM-DD'));

              }
              else if (value === 'thisWeek') {
                // get start date of week
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var firstday = new Date(curr.setDate(first)).toUTCString();
                setStartDate(moment(firstday).format('YYYY-MM-DD'));
                setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              }
              else if (value === 'thisWeekLastWeek') {

                // last 2 weeks
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay() - 7; // First day is the day of the month - the day of the week
                var firstday = new Date(curr.setDate(first)).toUTCString();
                setStartDate(moment(firstday).format('YYYY-MM-DD'));
                setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              }
              else if (value === 'thisMonth') {

                // this month
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDate() + 1 // First day is the day of the month - the day of the week
                var firstday = new Date(curr.setDate(first)).toUTCString();
                setStartDate(moment(firstday).format('YYYY-MM-DD'));
                setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              }
              else if (value === 'lastMonth') {

                // set first and last month dates
                var curr = moment(new Date())
                var endDate = moment(new Date())
                var first = curr.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                var lastDay = endDate.subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                setStartDate(moment(first).format('YYYY-MM-DD'));
                setEndDate(moment(lastDay).format('YYYY-MM-DD'));

              }
              else if (value === 'custom') {
                setStartDate(null);
                setEndDate(null);
                setShowDatePicker(true);
              } else if (value === 'custom-week') {
                setStartDate(null);
                setEndDate(null);
                setShowWeekPicker(true);
              }
              else if (value === 'custom-month') {
                setStartDate(null);
                setEndDate(null);
                setShowMonthPicker(true);
              }
              else if (value === 'custom-time') {
                setShowDateTimePicker(true)
              }
            }}
            // value={typeValue}
            defaultValue={"DEFAULT"}
          >
            <option value='DEFAULT' disabled>
              Select Time Period

            </option>

            {reportTimePeriod(reportType || '').map(function (sensor, index) {
              return (
                <option key={index} value={sensor.value}>
                  {sensor.name}
                </option>
              );
            })}
          </StyledSelect>

        </FormWrapper>



        {showDatePicker && <>
          <FormWrapper>

            <label htmlFor="startDate"> Date</label>
            <StyledInput type="date" id="startDate" name="startDate" max={maxDate} min={minDate} onChange={
              (e) => {
                setshowGraph(false);
                setStartDate(e.target.value);
                setEndDate(e.target.value);
                // add 7 days to start date
                // var d = new Date(e.target.value);
                // d.setDate(d.getDate() + 180);
                // setMaxEndDate(moment(d).format('YYYY-MM-DD'));

              }
            } />
          </FormWrapper>
        </>}

        {showWeekPicker && <>
          <FormWrapper>

            <label htmlFor="weekDate">Week</label>
            <StyledInput type="week" id="weekDate"
              min={
                moment(minDate).format('YYYY-[W]WW')
              }
              max={
                moment(maxDate).format('YYYY-[W]WW')
              }
              name="weekDate"
              onChange={
                (e) => {
                  setshowGraph(false);
                  // console.log({ e });
                  const [year, week] = e.target.value.split('-W');
                  var d = new Date(year, 0, 1 + (week - 1) * 7);
                  var dayNum = d.getDay();
                  var diff = d.getDate() - dayNum + (dayNum === 0 ? -6 : 1);
                  var weekStart = new Date(d.setDate(diff));
                  var weekEnd = new Date(d.setDate(diff + 6));
                  setStartDate(moment(weekStart).format('YYYY-MM-DD'));
                  setEndDate(moment(weekEnd).format('YYYY-MM-DD'));

                  // setStartDate(e.target.value);
                  // setEndDate(e.target.value);
                  // add 7 days to start date
                  // var d = new Date(e.target.value);
                  // d.setDate(d.getDate() + 180);
                  // setMaxEndDate(moment(d).format('YYYY-MM-DD'));

                }
              } />
          </FormWrapper>
        </>}

        {showMonthPicker && <>
          <FormWrapper>

            <label htmlFor="monthDate">Month</label>
            <StyledInput type="month" id="monthDate"
              min={
                moment(minDate).format('YYYY-MM')
              }
              max={
                moment(maxDate).format('YYYY-MM')
              }
              name="monthDate"
              onChange={
                (e) => {
                  setshowGraph(false);
                  // console.log({ e });
                  const [year, month] = e.target.value.split('-');
                  var d = new Date(year, month - 1, 1);
                  var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
                  setStartDate(moment(d).format('YYYY-MM-DD'));
                  setEndDate(moment(lastDay).format('YYYY-MM-DD'));

                }
              } />
          </FormWrapper>
        </>}

        {showDateTimePicker && <>
          <FormWrapper>
            <div className="text-center">
              <div htmlFor="date">Select Date</div>
              <StyledInput type="date" id="date" name="date" max={
                moment(new Date()).format('YYYY-MM-DD')
              } onChange={
                (e) => {
                  setshowGraph(false);
                  setReportDate(e.target.value);
                  setStartDate(null);
                  setEndDate(null);
                }
              } />

            </div>
            <div className="text-center">
              <div htmlFor="startDate">Start Time</div>
              <StyledInput type="time" id="startDateTime" name="startDateTime"
                value={startDate ? moment(startDate).format('HH:mm') : ''}
                onChange={
                  (e) => {
                    setshowGraph(false);
                    //  set date and time
                    setStartDate(moment(`${reportDate} ${e.target.value}`).format('YYYY-MM-DD HH:mm'));
                    // setStartDate(e.target.value);
                  }
                } />

            </div>

            <div className="text-center">
              <div htmlFor="endDate">End Time</div>

              <StyledInput type="time"
                value={endDate ? moment(endDate).format('HH:mm') : ''}
                id="endDateTime" name="endDateTime" onChange={
                  (e) => {
                    setshowGraph(false);
                    // setEndDate(e.target.value);
                    setEndDate(moment(`${reportDate} ${e.target.value}`).format('YYYY-MM-DD HH:mm'));
                  }} />

            </div>

          </FormWrapper>

        </>}


        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem'
        }}>

          <StyledBtn type="submit" value="Submit"
            disabled={reportType === 'DEFAULT' || typeValue === 'DEFAULT' || !reportType || !typeValue || !startDate}
          >
            Get Report
          </StyledBtn>

          <IconBtn
            type="button"
            disabled={showGraph === false || progress > 0}
            // disabled={reportType === 'DEFAULT' || typeValue === 'DEFAULT' || !reportType || !typeValue || !startDate}
            onClick={() => {
              // console.log(elementsRef.current);
              const uniqueElements = Object.values(componentRefs.current);
              downloadPDF(uniqueElements, setProgress)
            }}
          >
            <FaDownload size={20} />

          </IconBtn>
        </div>
        {progress > 0 && (
          <ProgressBar progress={progress} />
        )}
      </StyledForm>


      {/* {console.log({
        startDate, endDate, reportType, con: showGraph &&  && (reportType !== 'Consumption' ||
          reportType !== 'Filled')
      })} */}
      {
        showGraph && (reportType === 'Trend' ||
          (startDate !== endDate &&
            (reportType === 'Consumption' ||
              reportType === 'Filled'))) &&
        list[reportType][typeValue].map((sensor, idx) =>
        (
          <>
            <WeeklyReport
              key={idx}
              ref={el => addRef(`${sensor?.sensor_id}_${reportType}_Trend`, el)}
              sensor_id={sensor?.sensor_id}
              motor_id={sensor?.motor_id}
              sensor_type={`${typeValue} ${reportType}`}
              location={sensor?.location_name || sensor?.location}
              startDate={startDate}
              timePeriod={timePeriod}
              reportType={reportType}
              {...(endDate && { endDate: endDate })}

            />
          </>
        )
        )
      }


      {
        showGraph && startDate === endDate &&
        (reportType === 'Consumption' ||
          reportType === 'Filled') &&
        list[reportType][typeValue].map((sensor, idx) =>
        (<>
          <HourlyReport
            key={idx}
            ref={el => addRef(`${sensor?.sensor_id}_${reportType}`, el)}
            sensor_id={sensor?.sensor_id}
            motor_id={sensor?.motor_id}
            sensor_type={`${typeValue} ${reportType}`}
            location={sensor?.location_name || sensor?.location}
            startDate={startDate}
            timePeriod={timePeriod}
            reportType={reportType}
            {...(endDate && { endDate: endDate })}

          />


          {sensor?.borepumpReportFlag &&

            <div style={{
              backgroundColor: 'white',
            }}>
              {list?.Trend?.Borewell?.map((sensor, idx) =>

                <WeeklyReportV2
                  key={idx}
                  ref={el => addRef(`borewell_${sensor?.motor_id}`, el)}
                  sensor_id={sensor?.sensor_id}
                  motor_id={sensor?.motor_id}
                  sensor_type={`Borewell Trend`}
                  location={sensor?.location_name || sensor?.location}
                  startDate={startDate}
                  timePeriod={timePeriod}
                  reportType={'Trend'}
                  {...(endDate && { endDate: endDate })}
                />
              )}
            </div>
          }
        </>
        )
        )
      }



      {/* new report */}






      {/* {showGraph && <WeeklyReport sensor_id={typeValue.split('+')[1]} sensor_type={typeValue.split('+')[0]} />} */}
    </Container>
  );
}
