import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  HomeIcon,
  ReportIcon,
  ProfileIcon,
  LogoutIcon,
} from "../../assets/icons/index";
import { Logo } from "../../assets/svg/index";
import axios from "axios";

const NavWrapper = styled.div`
  @media (min-width: 61.313em) {
    margin: 1rem 2rem;
    display: flex;

    justify-content: space-between;
  }
`;
const NavLinks = styled.ul`
  display: flex;
`;
const LogoWrapper = styled.div`
  width: 150px;
  height: auto;
`;
const LinkName = styled.h6`
  visibility: hidden;
  margin-top: 0.2rem;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: var(--primary);
    }
    ${LinkName} {
      visibility: visible;
    }
  }
`;

export default function HeaderDesktop() {
  const logoutUser = async () => {

    await axios.get(`${process.env.REACT_APP_API}/user/logout`, {
      withCredentials: true
    })

    // localStorage.removeItem("firstLogin");
    localStorage.clear()
    window.location.href = "/";
  };

  return (
    <NavWrapper>


      <Link to="/"> <LogoWrapper>
        <Logo />
      </LogoWrapper></Link>
      <NavLinks>

        <Link to="/">
          <IconWrapper>
            <HomeIcon />
            <LinkName>Home</LinkName>
          </IconWrapper>
        </Link>
        <Link to="/report">
          <IconWrapper>
            <ReportIcon />
            <LinkName>Report</LinkName>
          </IconWrapper>
        </Link>
        <Link to="/profile">
          <IconWrapper>
            <ProfileIcon />
            <LinkName>Profile</LinkName>
          </IconWrapper>
        </Link>
        <Link to="/" onClick={logoutUser}>
          <IconWrapper>
            <LogoutIcon />
            <LinkName>Logout</LinkName>
          </IconWrapper>
        </Link>
      </NavLinks>
    </NavWrapper>
  );
}
