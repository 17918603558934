import axios from "axios";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IoInformationCircle } from "react-icons/io5";
import styled from "styled-components";
import { GlobalState } from "../../GlobalState";
import Loading from "../utils/loading";
import Tooltip from "../utils/Tooltip";
import LineGraph from "./LineGraph";




const Container = styled.div`
  background-color: #fff;
  // max-height: fit-content;
  height: 100%;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  border-radius: 20px;
`;


const GraphWrapper = styled.div`
  margin: 2rem auto;
  background-color: #fff;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
`;
const Header = styled.div`
  float:right;
`;

const DownloadBtn = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  margin: 0 1rem;
  color: grey;

  &:hover {
    color: green;
  }
`;
function TodayReport({ sensor_id, sensor_type, toShow }) {


  const state = useContext(GlobalState);
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [Data2, setData2] = useState(null);


  var date = new Date().toLocaleDateString();
  const saveCanvas1 = () => {
    //save to png
    const canvasSave = document.getElementById("canvas1");
    // add background color to canvas
    // const ctx = canvasSave.getContext("2d");
    // ctx.fillStyle = "#fff";

    // canvasSave.style.backgroundColor = "#fff";

    // canvasSave.toBlob(function (blob) {
    //   saveAs(blob, `${date}___${sensor_type}.jpg`);
    // });

    const imageLink = document.createElement('a');

    imageLink.download = 'img.png';
    imageLink.href = canvasSave.current.toBase64Image('image/jpg', 1);

    imageLink.click();

  };
  const saveCanvas2 = () => {
    //save to png
    const canvasSave = document.getElementById("canvas2");
    // const ctx = canvasSave.getContext("2d");
    // ctx.fillStyle = "#fff";
    // canvasSave.style.backgroundColor = "#fff";

    // canvasSave.toBlob(function (blob) {
    //   saveAs(blob, `${date}___${sensor_type}`);
    // });



    const imageLink = document.createElement('a');

    imageLink.download = 'img.png';
    imageLink.href = canvasSave.current.toBase64Image('image/jpg', 1);

    imageLink.click();
  };

  useEffect(() => {

    async function fetchData(token) {
      try {
        const data = await axios.get(`${process.env.REACT_APP_API}/sensor/report?sensor_id=${sensor_id}&limit=${`48`}&type=${`daily`}&sensor_type=${sensor_type}`,
          {
            headers: { Authorization: token[0] },
          }
        );



        if (sensor_type === 'Humidity' && toShow === 'Temperature') {
          const data2 = await axios.get(
            `${process.env.REACT_APP_API}/sensor/report?sensor_id=${sensor_id}&limit=${`48`}&type=${`daily`}&sensor_type=${'Temperature'}`,
            {
              headers: { Authorization: token[0] },
            }
          );
          if (data2) {
            setData2(data2.data);
            setLoading(false);
          }

        }

        if (data) {
          setData(data.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchData(state.token);
  }, [state.token, sensor_id, sensor_type, toShow]);


  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <div>

      {Data2 && sensor_type === 'Humidity' && toShow === 'Temperature' ? (
        <Container>
          <Header>
            <DownloadBtn title={"Download graph 2"}>
              <FaCloudDownloadAlt onClick={saveCanvas2} size={25} />
            </DownloadBtn>
            <Tooltip
              content={
                <>
                  Hang tight! Sometimes it may take <br /> upto 5 sec to generate
                  report.
                </>
              }
              direction="left"
              delay="0"
            >
              <IoInformationCircle size={25} style={{ color: "grey" }} />
            </Tooltip>
          </Header>
          <GraphWrapper>
            <LineGraph
              type={'Temperature'}
              id={"canvas2"}
              timestamps={Data2.timestamps}
              data={Data2.datas}
              unit={Data2.unit}
            />
          </GraphWrapper>
        </Container>
      ) : (
        <Container>
          <Header>

            <DownloadBtn title={"Download graph 3"}>
              <FaCloudDownloadAlt onClick={saveCanvas1} size={25} />
            </DownloadBtn>

            <Tooltip
              content={
                <>
                  Hang tight! Sometimes it may take <br /> upto 5 sec to generate
                  report.
                </>
              }
              direction="left"
              delay="0"
            >
              <IoInformationCircle size={25} style={{ color: "grey" }} />
            </Tooltip>
          </Header>

          <GraphWrapper>
            <LineGraph
              id={"canvas1"}
              type={sensor_type}
              timestamps={Data.timestamps}
              data={Data.datas}
              unit={Data.unit}
            />
          </GraphWrapper>
        </Container>)

      }
    </div>
  );
}

export default TodayReport;
